<template>
  <div>
    <!-- Show the table view -->
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :loading="isMakingRequest"
      :page="currentPage"
      :server-items-length="response.total || 0"
      :items-per-page="perPage"
      @update:page="_ => { currentPage = _; fetchPosts(); }"
      @update:items-per-page="_ => { perPage = _; fetchPosts() }"
      class="elevation-1 posts-data-table"
      item-key="id"
    >
      <!-- Modify the platform column -->
      <template v-slot:item.platform="{ item }">
        <div>
          <v-img
            :src="`/img/socials/${item.platform}.svg`"
            max-width="32"
            height="32"
          ></v-img>
        </div>
      </template>

      <!-- Modify the influencer column -->
      <template v-slot:item.username="{ item }">
        <profile-chip
          :platform="item.platform"
          :data="item"
        />
      </template>

      <template v-slot:item.thumbnail="{ item }">
        <div class="pointer">
          <v-img
            @click="selectedItem = item; shouldShowPreviewDialog = true;"
            :src="proxyUrl(item.thumbnail)"
            crossorigin="anonymous"
            max-width="32"
            height="32"
            cover
          />
        </div>
      </template>

      <!-- Show the date -->
      <template v-slot:item.posted_at="{ item }">
        {{ dayjs(item.posted_at).format("ll") }}
      </template>

      <!-- Modify the total followers column -->
      <template v-slot:item.followers="{ item }">
        <span :title="item.followers">
          {{ item.followers ? nFormatter(item.followers) : 'NA' }}
        </span>
      </template>

      <!-- Modify the likes column -->
      <template v-slot:item.likes="{ item }">
        <span :title="item.likes">
          {{ nFormatter(item.likes) }}
        </span>
      </template>

      <!-- Modify the dislikes column -->
      <template v-slot:item.dislikes="{ item }">
        <span :title="item.dislikes">
          {{ item.dislikes ? nFormatter(item.dislikes) : 'NA' }}
        </span>
      </template>

      <!-- Modify the total comments column -->
      <template v-slot:item.comments="{ item }">
        <span :title="item.comments">
          {{ nFormatter(item.comments) }}
        </span>
      </template>

      <!-- Modify the total views column -->
      <template v-slot:item.views="{ item }">
        <span :title="item.views">
          {{ item.views ? nFormatter(item.views) : 'NA' }}
        </span>
      </template>

      <!-- Modify the total shares column -->
      <template v-slot:item.shares="{ item }">
        <span :title="item.shares">
          {{ item.shares ? nFormatter(item.shares) : 'NA' }}
        </span>
      </template>

      <!-- Modify the total saves/bookmarks column -->
      <template v-slot:item.saves="{ item }">
        <span :title="item.saves">
          {{ item.saves ? nFormatter(item.saves) : 'NA' }}
        </span>
      </template>

      <!-- Modify the average engagement column -->
      <template v-slot:item.engagement_rate="{ item }">
        <span :title="item.engagement_rate * 100">
          {{ item.engagement_rate ? (item.engagement_rate * 100).toFixed(2) + '%' : 'NA' }}
        </span>
      </template>

      <!-- Modify the actions column -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            small
            depressed
            color="primary"
            @click="redirectToWeb(item.url)"
          >
            View

            <v-icon right>
              open_in_new
            </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            v-if="overview.canUserWrite"
            transition="slide-y-transition"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="120" dense>
              <!-- Show the edit button -->
              <v-list-item
                @click="showEditDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit Values
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the update button -->
              <v-list-item
                @click="showSyncDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Sync Values
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                @click="showDeleteDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete Post
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the preview dialog -->
    <v-dialog
      v-model="shouldShowPreviewDialog"
      max-width="338"
    >
      <v-card-text
        v-if="selectedItem"
        class="px-0 py-0"
      >
        <v-img
          :src="proxyUrl(selectedItem.thumbnail)"
          class="white"
          max-width="338"
          max-height="600"
          contain
        />
      </v-card-text>
    </v-dialog>

    <!-- Show an edit post dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      :persistent="isMakingEditRequest"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text">
          Edit Values
        </v-card-title>

        <v-card-text class="pt-4">
          <p>
            Once you edit these values, we'll stop auto-syncing the live values.
          </p>

          <v-row class="mt-2">
            <!-- Likes -->
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.likes"
                color="primary"
                label="Likes"
                type="number"
                :hide-details="!$v.form.likes.$anyError"
                :error-messages="$v.form.likes.$anyError ? ['Invalid value'] : null"
                @blur="$v.form.likes.$touch"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Dislikes -->
            <v-col cols="12" sm="6" v-if="selectedItem.platform === 'youtube'">
              <v-text-field
                v-model="form.dislikes"
                color="primary"
                label="Dislikes"
                type="number"
                :hide-details="!$v.form.dislikes.$anyError"
                :error-messages="$v.form.dislikes.$anyError ? ['Invalid value'] : null"
                @blur="$v.form.dislikes.$touch"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Views -->
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.views"
                color="primary"
                label="Views"
                type="number"
                :hide-details="!$v.form.views.$anyError"
                :error-messages="$v.form.views.$anyError ? ['Invalid value'] : null"
                @blur="$v.form.views.$touch"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Comments -->
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.comments"
                color="primary"
                label="Comments"
                type="number"
                :hide-details="!$v.form.comments.$anyError"
                :error-messages="$v.form.comments.$anyError ? ['Invalid value'] : null"
                @blur="$v.form.comments.$touch"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Saves -->
            <v-col cols="12" sm="6" v-if="selectedItem.platform === 'tiktok'">
              <v-text-field
                v-model="form.saves"
                color="primary"
                label="Saves"
                type="number"
                :hide-details="!$v.form.saves.$anyError"
                :error-messages="$v.form.saves.$anyError ? ['Invalid value'] : null"
                @blur="$v.form.saves.$touch"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- Shares -->
            <v-col cols="12" sm="6" v-if="selectedItem.platform !== 'youtube'">
              <v-text-field
                v-model="form.shares"
                color="primary"
                label="Shares"
                type="number"
                :hide-details="!$v.form.shares.$anyError"
                :error-messages="$v.form.shares.$anyError ? ['Invalid value'] : null"
                @blur="$v.form.shares.$touch"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4 pr-6">
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingEditRequest"
            :loading="isMakingEditRequest"
            @click="handleEditPost"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show an attach post dialog -->
    <v-dialog
      v-model="shouldShowSyncDialog"
      :persistent="isMakingSyncRequest"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text">
          Sync Values
        </v-card-title>

        <v-card-text class="pt-4">
          <p>
            You've manually edited the values, if you continue, we'd overwrite them with live values. Do you want to continue?
          </p>
        </v-card-text>

        <v-card-actions class="pb-4 pr-6">
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingSyncRequest"
            :loading="isMakingSyncRequest"
            @click="handleSyncPost"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show a delete post dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingDeleteRequest"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text">
          Delete Post?
        </v-card-title>

        <v-card-text class="pt-4">
          <p v-if="selectedItem.added_manually">
            This post was added manually, are you sure you want to delete it?
          </p>
          <p v-else>
            Are you sure you want to delete this post?
          </p>
        </v-card-text>

        <v-card-actions class="pb-4 pr-6">
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingDeleteRequest"
            :loading="isMakingDeleteRequest"
            @click="handleDeletePost"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, minValue, maxValue, numeric } from "vuelidate/lib/validators"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")

// The form object generator
const formGenerator = (item) => ({
  likes: item ? item.likes : 0,
  dislikes: item ? item.dislikes : 0,
  views: item ? item.views : 0,
  comments: item ? item.comments : 0,
  shares: item ? item.shares : 0,
  saves: item ? item.saves : 0
})

// Export the SFC
export default {
  // Name of the component
  name: "PostsTable",

  // Register children components
  components: {
    ProfileChip,
    StatusChip
  },

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    },

    shouldShowActions: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  // Define local data variables
  data: () => ({
    // The selected post model
    selectedItem: null,

    // Whether or not to show the dialogs
    shouldShowEditDialog: false,
    shouldShowSyncDialog: false,
    shouldShowDeleteDialog: false,
    shouldShowPreviewDialog: false,

    // Whether or not a request is being made currently
    isMakingRequest: false,
    isMakingEditRequest: false,
    isMakingSyncRequest: false,
    isMakingDeleteRequest: false,

    // The form object for edit values
    form: formGenerator(null),

    // List of posts that were refreshed recently
    refreshedPosts: [],
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get all the table header items
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "",
          value: "platform",
          sortable: false,
        },
        {
          text: "Influencer",
          value: "username",
          sortable: false,
          sort: (a, b) => a.localeCompare(b)
        },
        {
          text: "",
          value: "thumbnail",
          sortable: false,
        },
        {
          text: "Posted",
          value: "posted_at",
          sortable: false,
        },
        {
          text: "Followers",
          value: "followers",
          sortable: false
        },
        {
          text: "Likes",
          value: "likes",
          sortable: false,
          condition: this.tableItems.some((item) => item.likes > 0)
        },
        {
          text: "Dislikes",
          value: "dislikes",
          sortable: false,
          condition: this.tableItems.some((item) => item.dislikes > 0)
        },
        {
          text: "Comments",
          value: "comments",
          sortable: false,
          condition: this.tableItems.some((item) => item.comments > 0)
        },
        {
          text: "Views",
          value: "views",
          sortable: false,
          condition: this.tableItems.some((item) => item.views > 0)
        },
        {
          text: "Shares",
          value: "shares",
          sortable: false,
          condition: this.tableItems.some((item) => item.shares > 0)
        },
        {
          text: "Saves",
          value: "saves",
          sortable: false,
          condition: this.tableItems.some((item) => item.saves > 0)
        },
        {
          text: "Eng Rate",
          value: "engagement_rate",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "right",
          condition: this.shouldShowActions === true
        }
      ].filter((item) => typeof item.condition === "boolean" ? item.condition : true)
    },

    /**
     * Get all the mapped data objects for table view
     *
     * @returns {Array}
     */
    tableItems() {
      return (this.response?.data || []).map((item) => {
        return {
          id: item.id,
          url: item.url,
          platform: item.platform,
          username: item.username,
          fullname: item.fullname,
          account_id: item.account_id,
          thumbnail: item.thumbnail,
          posted_at: item.posted_at,
          followers: item.followers,
          picture: item.picture,
          is_verified: item.is_verified,
          likes: item.likes,
          dislikes: item.dislikes,
          comments: item.comments,
          views: item.views,
          shares: item.shares,
          saves: item.saves,
          engagement_rate: item.followers ? ((item.likes || 0) + (item.comments || 0) + (item.shares || 0) || (item.saves || 0)) / item.followers : null,
          added_manually: item.added_manually,
          edited_manually: item.edited_manually
        }
      })
    },

    /**
     * Get the query object for the posts
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["campaignTracking/postsTableQueryById"](this.overview.model.id)
    },

    /**
     * Get the current page number
     *
     * @returns {Number}
     */
    currentPage: {
      get() {
        return this.query.pagination.page
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updatePostsTableQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              page: value
            }
          }
        })
      }
    },

    /**
     * Get the current per page value
     *
     * @returns {Number}
     */
    perPage: {
      get() {
        return this.query.pagination.perPage
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updatePostsTableQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              perPage: value
            }
          }
        })
      }
    },

    /**
     * Get the response object for the posts
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["campaignTracking/postsTableResponseById"](this.overview.model.id)
    },

    /**
     * Get the selected platform
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id)
    },

    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
    selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    }
  },

  // Set watchers for local data
  watch: {
    // Whenever the selected platform changes
    selectedPlatform: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchPosts()
      },

      deep: true
    },

    // Whenever the selected influencer changes
    selectedInfluencer: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchPosts()
      },

      deep: true
    }
  },

  // Define vuelidate validators
  validations: {
    form: {
      likes: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      dislikes: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      views: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100_000_000_000)
      },
      comments: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      shares: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
      saves: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1_000_000_000)
      },
    }
  },

  // Define local method functions
  methods: {
    /**
     * Show a dialog to the user
     *
     * @param {Object} item
     * @returns {void}
     */
    showEditDialog(item) {
      // Update predefined values
      this.form = formGenerator(item)

      // Mark the object as selected
      this.selectedItem = item

      // Show the dialog
      this.shouldShowEditDialog = true
    },

    /**
     * Make a network request to save these values
     *
     * @returns {void}
     */
    async handleEditPost() {
      // Validate the form
      await this.$v.form.$touch()

      // If it's not valid
      if (this.$v.form.$anyError) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please enter valid numeric values" })

        // Stop further execution
        return
      }

      // Show a loader
      this.isMakingEditRequest = true

      // Try making a network request
      try {
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/posts/${this.selectedItem.id}`,
          method: "PUT",
          data: this.form
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Values updated!" })

        // Hide the dialog
        this.shouldShowEditDialog = false

        // Dispatch an event to refresh the values
        window.dispatchEvent(new CustomEvent("campaignTracking:refreshPosts", { detail: this.overview.model.id }))

        // Refresh the data
        this.fetchPosts()
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "CampaignTracking/Posts Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nonetheless
      finally {
        this.isMakingEditRequest = false
      }
    },

    /**
     * Show a dialog to the user
     *
     * @param {Object} item
     * @returns {void}
     */
    showSyncDialog(item) {
      // Mark the selected item
      this.selectedItem = item

      // If we have edited it before
      if (item.edited_manually) {
        // Show the dialog
        this.shouldShowSyncDialog = true
      }
      // Otherwise
      else {
        // Make the network request
        this.handleSyncPost()
      }
    },

    /**
     * Make a network request to save these values
     *
     * @returns {void}
     */
    async handleSyncPost() {
      // If it was refreshed just now
      if (this.refreshedPosts.includes(this.selectedItem.id)) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "You refreshed this post recently, please try again later" })

        // Stop further execution
        return
      }

      // Show a loader
      this.isMakingSyncRequest = true

      // Try making a network request
      try {
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/posts/${this.selectedItem.id}/refresh`,
          method: "POST",
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Please wait for a while..." })

        // Hide the dialog
        this.shouldShowSyncDialog = false

        // Push the id in refreshed list
        this.refreshedPosts.push(this.selectedItem.id)
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "CampaignTracking/Sync Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nonetheless
      finally {
        this.isMakingSyncRequest = false
      }
    },

    /**
     * Show the delete confirmation dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showDeleteDialog(item) {
      // Mark the selected item
      this.selectedItem = item

      // Show the dialog
      this.shouldShowDeleteDialog = true
    },

    /**
     * Make a network request to delete this post
     *
     * @returns {void}
     */
    async handleDeletePost() {
      // Show a loader
      this.isMakingDeleteRequest = true

      // Try making a network request
      try {
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/posts/${this.selectedItem.id}`,
          method: "DELETE",
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Post removed!" })

        // Hide the dialog
        this.shouldShowDeleteDialog = false

        // Dispatch an event to refresh the values
        window.dispatchEvent(new CustomEvent("campaignTracking:refreshPosts", { detail: this.overview.model.id }))

        // Refresh local data
        this.fetchPosts()
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "CampaignTracking/Posts Delete Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nonetheless
      finally {
        this.isMakingDeleteRequest = false
      }
    },

    /**
     * Search for posts
     *
     * @returns {void}
     */
    async fetchPosts() {
      // Show a loader
      const loaderId = Symbol("CampaignTracking/fetchPostsTable")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.query.pagination.page,
            per_page: this.query.pagination.perPage,

            platform: this.selectedPlatform,
            influencer: this.selectedInfluencer ? this.selectedInfluencer.id : null,
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/campaign-tracking/${this.overview.model.id}/posts?${queryParams}`)

        // Update the response object
        this.$store.dispatch("campaignTracking/updatePostsTableResponse", {
          id: this.overview.model.id,
          response: response.data
        })
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch posts!" })

        // Log it
        logger({ type: "CampaignTracking/fetchPostsTable", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If the query value is not set
    if (!this.query) {
      // Set the default query value
      this.$store.dispatch("campaignTracking/updatePostsTableQuery", {
        id: this.overview.model.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        }
      })
    }

    // Ask to fetch the data from the server again
    this.fetchPosts()
  }
}
</script>

<style lang="stylus">
.posts-data-table thead tr th
  white-space nowrap !important
.posts-data-table tbody tr td
  white-space nowrap !important
</style>
